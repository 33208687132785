<template>
  <v-app>
    <v-main v-if="!dataloader">
      <div v-if="domaintrue" class="text-white f-size-14 d-flex justify-content-between align-center px-5 py-2" style="background-color: #005672;">
        <div>
          <span>{{ $t("geoipContent.text1") }} {{ countryname }}
            {{ $t("geoipContent.text2") }}
          </span>
          <span class="text-capitalize ms-1">{{ portalsiteTitle }}</span>.
          <!-- <span>{{ $t("geoipContent.text3") }}</span> -->
          <span>Click here if you wish to visit</span>
          <span @click="routerDomain" class="border-bottom border-1 fw-500 ms-1" style="cursor: pointer">
            {{ userDomain }}
          </span>
        </div>
        <div>
          <v-icon @click="domaintrue = false" color="red" class="bg-white rounded-circle">
            mdi-close-circle-outline
          </v-icon>
        </div>
      </div>

      <div>
        <div v-if="whatsappApi">
          <a :href="whatsappApi" class="whatsapp-float" target="_blank" rel="noopener noreferrer">
            <v-icon class="whatsappImg" size="40">mdi-whatsapp</v-icon>
          </a>
        </div>
        <div id="navbar">
          <headerCom @localeChanged="updateLocale" />
        </div>
        <!-- <homeComponent v-if="!otherpageRender"/> -->
        <div class="bg-pages">
          <router-view @localeChanged="updateLocale"></router-view>
        </div>
        <div id="food">
          <footerCom/>
        </div>
      </div>

      <v-dialog v-model="portalError" persistent>
        <div class="d-flex justify-center">
          <v-card class="p-4">
            <h3 class="text-center">Oops!</h3>
            <h5 class="text-center">We are experiencing technical issues at the moment.</h5>
            <h6 class="text-center">Please try refreshing the page or come back later.</h6>
            <div class="d-flex justify-space-evenly mt-3">
              <v-btn @click="reloadPage()" color="#162849">Retry</v-btn>
            </div>
          </v-card>
        </div>
      </v-dialog>
    </v-main>
    <div v-if="dataloader">
      <dataloader></dataloader>
    </div>
  </v-app>
</template>

<script>
import axios from "axios";
import headerCom from "@/components/headerCom1.vue";
// import homeComponent from "./components/homeComponent.vue";
// import country1 from "@/coundtrycode.json";
import CryptoJS from "crypto-js";
import moment from "moment";
import dataloader from "@/components/dataLoder.vue";
// import getConfigResIN from "@/getConfigResIN.json";
// import getConfigResUS from "@/getConfigResUS.json";
// import getConfigResCA from "@/getConfigResCA.json";

import footerCom from "@/components/footerCom.vue";

import { setConfigDataToLocalStorage, getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";
import { updateLocaleMessages } from '@/plugins/i18n.js';

export default {
  name: "App",
  components: {
    headerCom,
    // homeComponent,
    footerCom,
    dataloader
  },
  emits :{
    localeChanged: null,
  },
  
  data: () => ({
    pageRender: false,
    dataloader:true,
    otherpageRender:false,
    portalError:false,

    navbar: true,
    food: true,
    pageShow: false,
    countryname: "",
    visitcountryname: "",
    configResponse:"",

    i18nlocaleLang:"",
    whatsappApi: "",
    tawktoApi:"",

    dataDomainData: "",
    SearchDomain: "",
    portalsiteTitle: "",
    domaintrue: false,
    userDomain:"",
    envPortalCalled: false,

    countryOptions: [],
    siteContentData: "",
    languageData: "",
    getLanguages: "",
    countryIsocode: "",

    configReq: {
      service_type: "GetPortalConfigRQ",
      api_ver: "1",
      payload: {
        domain_name: "",
        env: "CERT",
        language: "EN",
        token: "jsdjOJDKJ7675",
      },
    },

    configUser_Id: "",

    getEndpoint:"",
    loaderReq:{
      "payload": {
        "domain": "uatin.traveloplex.com",
        "etoken": ""
      }
    }
  }),

  methods: {
    reloadPage() {
      setTimeout(() => {
        location.reload();
      }, 300);
      window.location.href = '/';
      this.portalError = false;
    },

    routerDomain(){
      localStorage.removeItem('prefDomain');
      window.location.href = `https://${this.userDomain}?ref=web&redirect=false`;
    },

    updateLocale(newLocale) {
      // Update the app's locale based on the event received from headerCom1.vue
      this.i18nlocaleLang = newLocale;
      console.log('App locale updated to:', this.i18nlocaleLang);
    },
    
    async getloaderPortal(){
      let hostname = window.location.hostname;
      if (hostname == "localhost") {
        console.log(hostname, "localhost....");
      } else {
        this.loaderReq.payload.domain = hostname;
        // this.loaderReq.payload.domain = hostname;
      }

      let Key = "8dsxBc9vDl4I9alHbJU7Dg4EBbr7AS0w";
      let domain_name = this.loaderReq.payload.domain;
      let current_date = moment(new Date()).format("YYYYMMDD");
      let fixed_value = 3;

      // console.log(current_date,'dasafsdfdasdasd');

      let compineskey = Key + domain_name + current_date + fixed_value.toString();
      const encryptedkey = CryptoJS.MD5(compineskey).toString();

      this.loaderReq.payload.etoken = encryptedkey

      // console.log(this.loaderReq,'loderred...2');

      await axios.post("https://otaget.nexus2.wistirna.com",this.loaderReq, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if(response.data.response_type === "success"){
            // this.pageRender = true;
            // this.dataloader = false;
            this.portalError = false;
            this.getEndpoint = response.data.endpoint;

            if(this.getEndpoint && !this.envPortalCalled){
              this.envPortal();
              this.envPortalCalled = true;
            }
          }
        })
        .catch((error) => {
          console.log("Error fetching application:", error);
          this.pageRender = false,
          this.dataloader = true;

          setTimeout(()=>{
            this.portalError = true;
            this.dataloader = false;
          },3000)
           // else{
          //   alert("Oops! Site is being updated. Sorry for the inconvenience. We will be right back with exciting new features. Please visit again after some time.");
          //   window.location.href = '/';
          // }
        });
    },

    envPortal() {
      this.configReq.payload.domain_name = this.loaderReq.payload.domain;

      console.log(this.configReq.payload.domain_name, "hshshshs....");
      console.log(this.configReq, "configReqconfigReqconfigReq");

      axios.get(`${this.getEndpoint}getportalconfig`, {
          params: this.configReq,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response) {
            let configResponse = response.data;
            setConfigDataToLocalStorage(configResponse);

            const getConfigResponse = getConfigDataFromLocalStorage();

             if(getConfigResponse){
               this.pageRender = true;
               this.dataloader = false;
               this.portalError = false;
               console.log(response, "configResponseconfigResponse");
   
               console.log(configResponse,'sdasdconfigRespasdasdonse');
               
               this.SearchDomain = this.configReq.payload.domain_name;
               this.portalsiteTitle = configResponse.payload.portal_configuration.site_title;
               this.countryname = configResponse.payload.portal_configuration.country.name;
               this.countryIsocode = configResponse.payload.portal_configuration.country.iso_code;
               this.countryOptions = configResponse.payload.portal_configuration.country_options;
               
               // Geolocation Redirection
               if(this.countryIsocode && this.countryOptions){
                 this.geolocationRedirection();
                }
                // Geolocation Redirection End
                
                this.siteContentData = configResponse.payload.portal_configuration.site_content_data;
                this.getLanguages = configResponse.payload.portal_configuration.languages;
               this.languageData = configResponse.payload.portal_configuration.language_data;
               // let portalurl = configResponse.payload.portal_configuration.geoip_location.portal_url;
               if (this.languageData) {
                 this.getlangaugeUrl();
               }
               else{
                 this.$i18n.locale = 'CA-en';
               }
   
   
               if (configResponse) {
                 this.pageShow = true;
               }
               this.indexHeader();
   
               if (this.getLanguages && this.countryIsocode) {
                 this.setLanguage();
                 console.log('langsetting...1')
                 // this.$i18n.locale = `${this.countryIsocode}-${this.getLanguages[0].code}`
                 // console.log(this.$i18n.locale, "langresponseresponse...1");
               }
               else{
                 this.$i18n.locale = 'CA-en';
                 console.log('langsetting...2')
                 // this.$i18n.locale = `${this.countryIsocode}-${this.getLanguages[0].code}`
               }
             }
          }
        })
        .catch((error) => {
          console.log("Error fetching data:", error);
          // alert("Something went difficulties...");
          this.pageRender = false;
          this.dataloader = true;
          
          setTimeout(()=>{
            this.portalError = true;
            this.dataloader = false;
          },3000)
        });
    },


    async geolocationRedirection(){
      const configResponse = await getConfigDataFromLocalStorage();
      // Geolocation Redirection

      if(configResponse){
        let geoIsocode = null;
        let geoCountrydomain = null;
        
        if(configResponse.payload.portal_configuration.geoip_location){
          geoIsocode = configResponse.payload.portal_configuration.geoip_location.country_iso_code;
          geoCountrydomain = configResponse.payload.portal_configuration.geoip_location.country_domain;
        }

        let findDomainIso = null;
        let findIsocode = null;
        if(this.countryOptions && geoIsocode){
          findDomainIso = this.countryOptions && geoIsocode ?  (this.countryOptions.find((v) => v.country_code == geoIsocode)) || null : null;
          findIsocode = this.countryIsocode && geoIsocode ? (this.countryIsocode == geoIsocode) || null : null;
        }

        let getredirect = null;
        if (this.$route.query && this.$route.query.redirect) {
          try {
            getredirect = JSON.parse(this.$route.query.redirect);
          } catch (e) {
            console.log("Invalid redirect value:", this.$route.query.redirect);
            getredirect = null;
          }
        }
        // const getrouteRef = this.$route.query && Object.keys(this.$route.query).length > 0 ? (this.$route.query.ref || null) : null; 
        const getusercountry = this.countryIsocode ? this.countryIsocode : null;
             
        const getrouteiso = this.$route.query ? (this.$route.query.usercountry || null ) : null;
        let getrouterquery = this.$route.query && Object.keys(this.$route.query).length > 0 ? this.$route.query : null;
        
        const validKeys = ["ref", "redirect", "usercountry"];
        const excludedKeys = ["ref", "redirect"];

        const hasOnlyValidKeys = getrouterquery && Object.keys(getrouterquery).every((key) => validKeys.includes(key));
        const hasOnlyExcludedKeys = getrouterquery && Object.keys(getrouterquery).every((key) => excludedKeys.includes(key));
        const isRefOnlyWeb = getrouterquery && Object.keys(getrouterquery).length === 1 && getrouterquery.ref === "web";
        const isRefWebwithRedirect = getrouterquery && Object.keys(getrouterquery).length === 2 && getrouterquery.ref === "web" && getredirect;
        
        const isUnwantedQuery = !getrouterquery || isRefOnlyWeb || (!isRefOnlyWeb && isRefWebwithRedirect) 
         || !hasOnlyValidKeys || (hasOnlyValidKeys && !hasOnlyExcludedKeys) ||(!hasOnlyValidKeys && hasOnlyExcludedKeys);
        
        // Encrypt User Country 
        let secretqueryKey = "secretquery";
        let encryptedquery = null;   
        
        if(isUnwantedQuery){
          if(findDomainIso && !findIsocode){
            if(isUnwantedQuery){
              encryptedquery = CryptoJS.AES.encrypt(JSON.stringify(getusercountry),secretqueryKey).toString();
              const encodequery = encryptedquery ? btoa(encryptedquery) : null;
              let encryptiso = encodequery ? encodequery : null;
              window.location.href = `${geoCountrydomain}?ref=web&redirect=true&usercountry=${encryptiso}`;
            }
          } 
        }
        else{
          this.domaintrue = false;
        }

        // Decrypt User Country 
        
        let finduserIso = null;
        if(getrouteiso){      
          const decodedEncrypted = getrouteiso ? atob(getrouteiso) : null;
          const decrypted = CryptoJS.AES.decrypt(decodedEncrypted, secretqueryKey).toString(CryptoJS.enc.Utf8); 
          
          // Encrypt & Decrypted User Country End
          let usercountryIso = decrypted ? JSON.parse(decrypted) : null;  
          finduserIso = this.countryOptions && usercountryIso ?  (this.countryOptions.find((s) => s.country_code == usercountryIso)) || null : null;

          if(getrouteiso && getredirect && finduserIso){
            this.domaintrue = true;
            let getuserDomain = finduserIso.portal_url ? finduserIso.portal_url : null; 
  
            let trimedDomain = getuserDomain.replace("https://","");
            this.userDomain = trimedDomain.charAt(0).toUpperCase() + trimedDomain.slice(1); 
          }
        }
        else{
          this.domaintrue = false;
        }
      }
      // Geolocation Redirection End
    },

    setLanguage() {
      let getLang = this.getLanguages[0].code;
      let savedLanguage = `${this.countryIsocode}-${getLang}`;
      localStorage.setItem("setlang", savedLanguage);
      // let setlocalLang = localStorage.setItem("setlang", savedLanguage);
      // if(setlocalLang){
      //   this.localeLanguage();
      // }
    },

    localeLanguage() {
      const storedLocale = localStorage.getItem("defaultlang");
      const storedSetLang = localStorage.getItem("setlang");

      let getlocalIso = "";
      let getlocalLang = "";
      let getsetIso = "";
      let getsetlang = "";
      // let savedLanguage = "";


      if (storedLocale) {
        getlocalIso = storedLocale.split('-')[0];
        getlocalLang = storedLocale.split('-')[1];
      }
      if (storedSetLang) {
        getsetIso = storedSetLang.split('-')[0];
        getsetlang = storedSetLang.split('-')[1];
      }


      if (storedSetLang && storedLocale) {
        console.log('Check...1');
        if (storedLocale == storedSetLang) {
          console.log('Check...2');

          return storedSetLang;
          // this.i18n.locale = storedSetLang;
        }
        else if (storedLocale !== storedSetLang) {
          console.log(getlocalIso, getsetIso, 'Check...3', getlocalLang, getsetlang);
          if (getlocalIso !== getsetIso && getlocalLang !== getsetlang || getlocalIso !== getsetIso && getlocalLang == getsetlang) {

            localStorage.removeItem("defaultlang");
            console.log('Check...4');
            return storedSetLang;
            // this.i18n.locale = storedSetLang;

          }
          else if (getlocalIso == getsetIso && getlocalLang !== getsetlang) {
            console.log(storedLocale, 'Check...5');

            return storedLocale;
            // this.i18n.locale = storedLocale;
          }
        }
      }
      else if (storedSetLang) {
        console.log('Check...6');

        return storedSetLang;
        // this.i18n.locale = storedSetLang;
      }
      else if (storedLocale) {
        console.log('Check...7');

        return storedLocale;
        // this.i18n.locale = storedLocale;
      }
      else {
        console.log('Check...8');

        return 'CA-en';
        // return `${this.countryIsocode}-${this.getLanguages[0].code}`;

        // this.i18n.locale = `${this.countryIsocode}-${this.getLanguages[0].code}`;
      }
    },


    getlangaugeUrl() {
      // this.dataloader = true;
      let langData = this.languageData
      // let getlocale = this.localeLanguage();
      // this.$i18n.locale = this.localeLanguage();
      var localLang = this.localeLanguage(); //CA.es

      console.log(localLang, "getegeteegtegete...1");

      // this.$i18n.locale = localLang;
      // console.log(this.$i18n.locale, "getegeteegtegete...2");
      let langdataUrl = "";
      // console.log(localLang,'localLanglocalLang')
      
      Object.keys(langData).forEach(v => {
        console.log(v,this.countryIsocode, 'vcountryIsocodevcountryIsocode...3');
        if (v == this.countryIsocode) {
          const lang = langData[v];

          Object.keys(lang).forEach(local => {
            console.log(local, 'dasasasasdas')
            if (local == localLang) {

              langdataUrl = lang[local]
              console.log(local, localLang, langdataUrl, 'dasasasasdas...2');

              axios.get(langdataUrl, {
                headers: {
                  "Content-Type": "text/html; charset=utf-8",
                },
              })
                .then((response) => {
                  if (response) {

                    console.log(response, "getegeteegtegete...3");
                    this.dataloader = false;
                    const cleanedData = this.parseLanguageData(response.data);
                    this.setLanguageMessages(localLang, cleanedData);                 
                  }
                })
                .catch((error) => {
                  console.log(error, 'errorrLangaugeAPI.....')
                  if(error){
                    setInterval(() => {
                      this.dataloader = false;
                    }, 3000);
                  }

                  this.$i18n ? (this.$i18n.locale ? this.$i18n.locale = 'CA-en' : null) : null;
                })
            }
          })
        }
      });
    },


    parseLanguageData(data) {
      try {
        const cleanedData = data
          .replace(/export\s+default\s+/, '')
          .replace(/;\s*$/, ''); // Remove trailing semicolon if present
        return eval(`(${cleanedData})`);
      } catch (error) {
        console.error('Error processing language content:', error);
        return {};
      }
    },

    // updateLocaleMessages(locale, messages) {
    //   // Update i18n with new locale messages
    //   updateLocaleMessages(locale, messages);
    //   console.log(this.$i18n.locale,'getegeteegtegete....4')
    // },

    setLanguageMessages(locale, messages) {
      // this.$i18n.setLocaleMessage(locale, messages);
      updateLocaleMessages(locale, messages);
      this.$i18n.locale = locale;

      console.log(this.$i18n.locale,'getegeteegtegete....4')
    },

    async indexHeader() {
      const getConfigData = await getConfigDataFromLocalStorage();

      if (getConfigData) {
        let portalTitle = getConfigData.payload.portal_configuration.site_title;
        let portalFavicon = getConfigData.payload.portal_configuration.favicon;
        document.getElementById("siteTitle").innerText = portalTitle;
        document.getElementById("faviconPortal").href = portalFavicon;

        let plugins = "";
        plugins = getConfigData.payload.portal_configuration.menus.enabled.plugins;
        // console.log(pluginWhats, 'dasdasdasda..1..1..1.1')

        if (plugins.includes("whatsapp")) {
          this.whatsappApi = getConfigData.payload.portal_configuration.API_endpoints.whatsapp;
        } else {
          this.whatsappApi = "";
        }
        // console.log(this.whatsappApi,'whatsappAPiiii')

        if (plugins.includes("tawkto")) {
          this.tawktoApi = getConfigData.payload.portal_configuration.API_endpoints.tawkto;
        } else {
          this.tawktoApi = "";
        }

        // TawkTo code
        const s1 = document.createElement("script");
        const s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = this.tawktoApi;
        s1.charset = "UTF-8";
        s1.setAttribute("crossorigin", "*");
        s0.parentNode.insertBefore(s1, s0);
      }
    },
  },

  watch: {
    "i18nlocaleLang": {
      immediate: true,
      handler: function (newValue) {
        // this.localeLanguage();
        console.log(newValue,'sdasdasdasnewValue');
        this.dataloader = true;
        console.log(this.dataloader,'getegeteegtegete....0')
        this.getlangaugeUrl();
        console.log(this.$i18n.locale,'getegeteegtegete....5')
      },
      deep: true,
    },

    "$route.path": {
      immediate: true,
      handler(newValue) {
        this.getloaderPortal();
        // this.otherpageRender = newValue !== "/" ? true : false;

        if (newValue === "/loader") {
          this.navbar = false;
          this.food = false;
        } else {
          this.navbar = true;
          this.food = true;
        }
        // console.log(newValue, '4545');
      },
    },
  },

  // created() {
  //   console.log(this.$i18n.locale, "getegeteegtegete....0");
  // },

  mounted(){
    this.getloaderPortal();
  }
  
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300&family=Poppins:wght@300;400;500&display=swap");

/* .bg-pages {
background: rgb(230, 217, 26);
background: linear-gradient(to right, rgb(45 129 253 / 65%), rgb(193 193 193));
} */

/* * {
  font-family: 'Poppins', sans-serif;
} */

* {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
}

#navbar {
  display: block;
}

#food {
  display: block;
}

.f-size-14 {
  font-size: 14px;
}

.f-size-16 {
  font-size: 16px;
}

.f-size-14 {
  font-size: 14px;
}

.f-size-13 {
  font-size: 13px;
}

.f-size-12 {
  font-size: 12px;
}

.f-size-11 {
  font-size: 11px;
}

.f-size-10 {
  font-size: 10px;
}

.f-size-9 {
  font-size: 9px;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.bg-pages {
  background: #f1f1f1;
  /* background-image:url('./assets/bmbg2.svg'); */
}

.v-main{
  background: #f1f1f1;
}

/* .bg-pages{
    background: linear-gradient(90deg, rgba(148, 187, 233, 0.7512254901960784) 0%, rgba(238, 174, 202, 1) 50%, rgba(148, 187, 233, 0.7456232492997199) 100%);
  } */
/* .bg-pages { */

/* background-image: url(https://andit.co/projects/html/and-tour/demo/assets/img/banner/bg.png); */
/* background: rgb(230, 217, 26); */
/* background: linear-gradient(277deg, rgba(230,217,26,1) 0%, rgba(51,175,167,1) 58%, rgba(253,45,164,1) 100%); */
/* background: linear-gradient(to right, rgb(45 129 253 / 65%), rgb(193 193 193)); */

/* margin-top:70px; */
/* background-image: url(https://andit.co/projects/html/and-tour/demo/assets/img/banner/bg.png); */
/* } */
</style>
