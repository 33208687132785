<template>
    <div v-if="ResetBox" class="my-5">
        <!-- <div class="trip">
            {{ $t("newPasswordContents.labels.createPassword") }}
        </div> -->
        <div class="py-5">
            <h4 class="text-center fw-bold mb-4 text-uppercase">{{ $t("newPasswordContents.labels.createPassword") }}</h4>

            <v-form v-model="valid" ref="form" @submit.prevent="resetSubmit">
                <div class="pre-card">
                    <v-card style="width: 550px;padding: 30px;">
                        <div class="col-12">
                            <v-text-field v-model="resetDetails.newPassword" :rules="uPass" :label="this.$t('newPasswordContents.labels.newPassword')"
                                placeholder="Enter New password" variant="underlined" required
                                :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" :type="visible ? 'text' : 'password'"
                                @click:append-inner="visible = !visible"
                                style="height:60px;font-size: 15px !important"></v-text-field>
                        </div>

                        <div class="col-12">
                            <v-text-field v-model="resetDetails.conPassword" :rules="uPass" :label="this.$t('newPasswordContents.labels.confirmPassword')"
                                placeholder="Enter Confirm password" variant="underlined" required
                                :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" :type="visible ? 'text' : 'password'"
                                @click:append-inner="visible = !visible"
                                style="height:60px;font-size: 15px !important"></v-text-field>
                        </div>

                        <div class="d-flex mt-3">
                            <div class="col-9 d-flex justify-end">
                                <v-btn color="#94C20E" width="60%" class="text-white" :disabled="btnDisabled"
                                    type="submit">{{$t("newPasswordContents.labels.resetBtn")}}</v-btn>
                            </div>
                            <div class="col-3 ps-1 d-flex justify-end">
                                <v-progress-circular v-if="loadRest" :width="3" style="color:navy"
                                    indeterminate></v-progress-circular>
                            </div>
                        </div>
                    </v-card>
                </div>
            </v-form>
        </div>
    </div>

    <div v-if="passUpdatedMsg" class="my-5 py-5">
        <v-card class="my-5 py-3 m-auto" style="width:fit-content; border-radius:7px;box-shadow: 0px 0px 8px 0px black; ">
          <div class="d-flex justify-center align-center p-3">
            <v-icon color="success" size="50">mdi-check-circle-outline</v-icon>
            <h4 class="text-success ps-2 m-0"> {{ successMsg }}...</h4>
          </div>
          <!-- <div class="d-flex justify-center align-center p-3">
            <p class="m-0">{{ registerMsg }}</p>
          </div> -->
          <div class="d-flex w-100 px-2 mb-2">
            <v-btn text="Return To Home" to="/" height="25px" class="text-capitalize text-white mx-auto" color="#94c20e"></v-btn>
          </div>
        </v-card>
    </div>
</template>


<script>
import axios from 'axios'

import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';


export default {

    data() {
        return {
            ResetBox: false,
            terms: false,
            visible: false,
            loadRest: false,
            btnDisabled: false,
            passUpdatedMsg:false,
            successMsg:'',

            loginshow: true,
            resetshow: false,


            resetDetails: {
                newPassword: "",
                conPassword: "",
            },

            eMail: [
                v => !!v || 'Please enter your email',
                v => /.+@.+/.test(v) || 'E-mail must be valid',
            ],
            uPass: [
                v => !!v || 'Please enter your Password'
            ],

            getId: "",
            getMail: "",

            userRequest: {
                "request_type": "reset_password",
                "email": "",
                "enkey": "qt3cU5nWjV2OPeQBEYkwD4adr8oy9I",
                "user_id": "",
                "portal_id": ""
            },

            resetRequest: {
                "request_type": "change_password",
                "new_password": "",
                "confirm_password": "",
                "email": "",
                "user_id": "",
                "portal_id": ""
            },

            checkPassword:"",
            updatePassword:"",
            portal_id:""
        }
    },
    methods: {

    
        checkingReset() {
            this.userRequest.enkey = this.getId
            this.userRequest.email = this.getMail
            this.userRequest.user_id = this.getMail
            this.userRequest.portal_id = this.portal_id
            this.userRequest.portal_domain = this.portal_domain

            console.log(this.userRequest, 'vvvvaaaallll')

            axios.post(this.checkPassword, this.userRequest, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                console.log(response, "dfsdvdff")
                if(response.data.reset_password_response=='success'){
                    this.ResetBox = true
                }
                else if(response.data.reset_password_response=='fail'){
                    this.ResetBox = false
                    this.$router.push('/')
                }
                else{
                    this.ResetBox = false
                    this.$router.push('/')
                }
            })
            .catch(errors => {
                console.log(errors, "eee....")
                this.ResetBox = false
                this.$router.push('/')
            })
        },


        resetSubmit() {
            this.loadRest = true;
            this.btnDisabled = true;
            if (this.valid) {
                this.resetRequest.email = this.getMail
                this.resetRequest.user_id = this.getMail
                this.resetRequest.portal_id = this.portal_id
                this.resetRequest.portal_domain = this.portal_domain
                this.resetRequest.new_password = this.resetDetails.newPassword
                this.resetRequest.confirm_password = this.resetDetails.conPassword

                console.log(this.resetRequest, 'resetRequest:....')

                axios.post(this.updatePassword,this.resetRequest,{
                    headers:{
                        'Content-Type':'application/json'
                    }
                })
                .then(response=>{
                    console.log(response,"responseforresetpass");
                    if(response.data.change_password_response == "success"){
                        this.successMsg = response.data.msg;
                        this.loadRest = false;
                        this.passUpdatedMsg = true;
                        this.ResetBox = false;
                        this.btnDisabled = true;
                    }
                })
                .catch(errors=>{
                    console.log(errors,"dfsdvdff")
                    this.loadRest = false;
                    this.passUpdatedMsg = false;
                    this.ResetBox = true;
                    this.btnDisabled = false;
                })
            }
        },

        getConfig() {
            // let portalLocal = localStorage.getItem("portalData")
            // let getConfigData1 = JSON.parse(portalLocal)
            const getConfigData1 = getConfigDataFromLocalStorage();
            if (getConfigData1) {
                this.checkPassword = getConfigData1.payload.portal_configuration.API_endpoints.check_password
                this.updatePassword = getConfigData1.payload.portal_configuration.API_endpoints.update_password
                this.portal_id = getConfigData1.payload.portal_configuration.portal_id
                this.portal_domain = getConfigData1.payload.portal_configuration.portal_url.replace("https://","");
            }
        },
    },

    created() {
        this.getConfig();

        this.getId = this.$route.query.enkey
        this.getMail = this.$route.query.email
        console.log(this.getId, this.getMail, 'gggggIIiiddi')

        this.checkingReset()
    }




}
</script>



<style scoped>
.trip {
    justify-content: center;
    display: flex;
    background-color: #f3d8cf;
    padding: 30px;
    font-size: 25px;
    font-weight: 600;
}

.pre-card {
    display: flex;
    justify-content: center;
    border-radius: 15px;
}

/* Login Page */

.main-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* .form-btn {
  padding-left: 40px;
  color: white;
} */

.btn-box {
    background-color: transparent;
    margin: 5px;
    padding: 2px;
    box-shadow: none;
}

.btn-active {
    background-color: blueviolet;
    color: white;
}

.v-checkbox .v-input__details {
    display: none;
}

>>>.v-label--clickable {
    cursor: pointer;
    font-size: 12px;
}

>>>.v-input__details {
    display: block !important;
}

.forgotStyle {
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 1px;
    color: #0D6EFD;
    text-decoration: underline;
    cursor: pointer;
}

/* Login Page End */



@media only screen and (max-width: 992px) {

    .form-btn {
        padding: 10px 0px 0px 0px !important;
        /* color: white; */
    }

    .btn-box,
    .pipe {
        color: blueviolet !important;
    }

    .btn-active {
        background-color: blueviolet;
        color: white !important;
    }
}
</style>
