<template>
  <section class="px-3 py-4 h-100 mb-0" style="background-color: #3c0ec2;">
    <div class="mt-5">
      <!-- <v-img :src="portalLogo" class="logo"/> -->
      <v-card class="pt-3 pb-4 p-3 m-auto mt-5 loadertextCard" style="max-width:600px">
        <v-img :src="portalLogo" class="logo"/>
        
        <div v-if="oneway || roundtrip">
          <v-toolbar class="h-auto mt-2">
              <v-progress-linear :active="progloader" :indeterminate="progloader" color="deep-purple-accent-4" absolute bottom></v-progress-linear>
              <div v-if="oneway || roundtrip" class="row w-100 my-3 text-center align-center gx-0">
                <div class="col-5">
                  <p class="fw-700" :class="{'m-0' : oneway}">{{ fromData }}</p>
                  <p class="f-size-14 fw-400 m-0" style="color:grey;" v-if="oneway ? false : true">{{ dedate }}</p>
                </div>
        
                <div class="col-2">
                  <v-img :src="require('../assets/arrowright.png')" class="arrowright"></v-img>
                  <v-img v-if="oneway ? false : true" :src="require('../assets/arrowleft.png')" class="arrowleft"></v-img>
                </div>
        
                <div class="col-5">
                  <p class="fw-700" :class="{'m-0' : oneway}">{{ toData }}</p>
                  <p class="f-size-14 fw-400 m-0" style="color:grey;" v-if="roundtrip">{{ returnDate }}</p>
                </div>
              </div>
          </v-toolbar>
    
          <div>
            <p class="text-center py-2 f-size-14 fw-500" 
            style="color: grey;background: aliceblue;">
            {{ !returnDate ? dedate + ' /' : null  }} {{parseFloat(adultcount) + parseFloat(childcount) + parseFloat(infantcount) }} Passenger(s)
             / {{ classType }} / {{ travelType === "RT" ? "Round" : "Oneway"}}-Trip</p>
          </div>
        </div>
  
  
        <div v-if="multi">
          <v-toolbar class="h-auto mt-2">
              <v-progress-linear :active="progloader" :indeterminate="progloader" color="deep-purple-accent-4" absolute bottom></v-progress-linear>
            
              <div class="row w-100 my-3 text-center align-center gx-0" v-for="(data,index) of multiData.itinery" :key="index">
                <div class="col-4">
                  <p class="fw-700">{{ data.from }}</p>
                </div>
              
                <div class="col-4">
                  <v-img :src="require('../assets/arrowright.png')" class="arrowright"></v-img>
                  <p class="f-size-14 fw-400"> {{ getDate(data.depdate)}}</p>
                </div>
              
                <div class="col-4">
                  <p class="fw-700">{{ data.to }}</p>
                </div>
              </div>
            </v-toolbar>
        
          <div>
            <p class="text-center py-2 f-size-14 fw-500" 
            style="color: grey;background: aliceblue;">
            {{parseFloat(adultcount) + parseFloat(childcount) + parseFloat(infantcount) }} Passenger(s)
             / {{ classType }} / Multicity</p>
          </div>
        </div>
  
  
        <div class="row my-4">
          <!-- <div class="col-md-4 d-flex justify-center border-grey border-dotted-1 border-right">
            <v-progress-circular :model-value="loaderValue" :rotate="360" :size="100" :width="15" color="teal">
              <template v-slot:default> {{ loaderValue }} % </template>
            </v-progress-circular>
          </div> -->
          <div class="col-md-12">
              <div class="slideset1">
                <div style="width: 100%" align="center">
                  <p class="fw-500">Please wait while we are checking availability and comparing the best fares...</p>
                </div>
  
                <div style="width: 100%" align="center">
                  <p class="fw-500">{{ $t('loaderPageContent.loadMsg1') }}.</p>
                </div>
  
                <div style="width: 100%" align="center">
                  <p class="fw-500">{{ $t('loaderPageContent.loadMsg2') }}.</p>
                </div>
  
                <div style="width: 100%" align="center">
                  <p class="fw-500">
                    {{ $t('loaderPageContent.loadMsg3') }}.
                  </p>
                </div>
              </div>
            </div>
        </div>
  
      </v-card>
    </div>

  </section>
</template>

<script>
import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";
import moment from "moment"
export default {
  props: {
    searchCase: String // Expect searchCase as a prop
  },
  // props: {
  //   searchCase: {
  //     type: Object,
  //     default: () => ({}),
  //   },
  // },
  data() {
    return {
      portalLogo: "",
      fromData: "",
      toData: "",
      dedate: "",
      returnDate: "",
      tripType: "",
      adultcount:"", 
      childcount: "",
      infantcount: "",
      classType: "",

      multi: false,
      roundtrip: false,
      oneway: false,
      // interval: 1000,
      multiData:[],
      searchData:"",
      // multiData: this.searchCase || '',
      // searchData: this.searchCase || '',
      items: [
        {
          src: require("@/assets/caro1.jpg"),
        },
      ],
      
      progloader:true,
      // interval: -1,
      // loaderValue: 0,
    };
  },
  
  watch: {
    searchCase(newValue) {
      // this.searchData = newValue;
      if (newValue) {
        this.loadSearchData(); 
      }
    },

    progloader (val) {
        if (!val) return

        setTimeout(() => (this.loading = false), 3000)
      },
  },

  mounted() {
    this.getConfigFunc();
    this.loadSearchData();

    // this.interval = setInterval(() => {
    //     if (this.loaderValue === 100) {
    //       return (this.loaderValue = 0)
    //     }
    //     this.loaderValue += 10
    //   }, 1500)
  },

  // beforeUnmount () {
  //     clearInterval(this.interval)
  //   },
  methods: {

    loadSearchData(){
      const tabId = sessionStorage.getItem("tabId");
      const tripType = this.$route.query.tripType;
      // console.log(this.searchCase,'storedDatasdasdasdas...1')


      let key = '';

      if (tripType === 'RT' || tripType === 'OW' ) {
        key = tripType === 'RT'? `searchedData-round-${tabId}` : `searchedData-oneway-${tabId}`;
        this.oneway = tripType === 'OW';
        this.roundtrip = tripType === 'RT';
        // console.log(key,'storedDatasdasdasdas...2');
      }
      else if (tripType === 'MC') {
        key = `searchedData-multicity-${tabId}`;
        this.multi = true;
        // console.log(key,'storedDatasdasdasdas...3');
      }

      const storedData = localStorage.getItem(key);
      // console.log(storedData,'storedDatasdasdasdas...4')


      if (storedData) {
        if (this.multi) {
          this.multiData = JSON.parse(storedData) || this.searchCase;
          this.adultcount = this.multiData.adult;
          this.childcount = this.multiData.child;
          this.infantcount = this.multiData.infant;
          this.classType = this.multiData.class;
          // console.log(this.multiData,'storedDatasdasdasdas...5');
        } else {
          this.searchData = JSON.parse(storedData) || this.searchCase;
          // console.log(this.searchData,'storedDatasdasdasdas...6');

          this.fromData = this.searchData.from;
          this.toData = this.searchData.to;
          this.dedate = moment(this.searchData.dedate).format("DD MMM-YYYY");

          if(tripType === 'RT'){
            this.returnDate = moment(this.searchData.redate).format("DD MMM-YYYY");
          }
          this.adultcount = this.searchData.adult;
          this.childcount = this.searchData.child;
          this.infantcount = this.searchData.infant;
          this.classType = this.searchData.class;
          this.travelType = tripType;
        }
      } else {
        console.log('No search data found for this tab.');
      }

      console.log(this.dedate, "mutiDatamutiData");
    },

    getDate(data){
      return moment(data).format("DD MMM-YYYY")
    },
    getConfigFunc() {
      const getConfigData = getConfigDataFromLocalStorage();

      console.log(getConfigData, "LOADERRR");

      if (getConfigData) {
        let Menus = "";
        Menus = getConfigData.payload.portal_configuration.menus.enabled.header;
        this.menuData =
          getConfigData.payload.portal_configuration.menus.enabled.header;
        this.headerMenus = Menus.split(", ");

        this.portalLogo = getConfigData.payload.portal_configuration.logo_path;
        console.log(this.portalLogo,'asdasdaportalLogo');
        
      }
    },
  },
  // created() {
  //   this.getConfigFunc();
  //   // this.loadSearchData();
  // },
};
</script>
<style scoped>
body {
  font-family: Arial, Helvetica, sans-serif;
}

.main-section {
  width: 100%;
  /* height: 500px; */
}

/* loader main */
.con {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  border-radius: 30px;
  max-width: 735px;
  margin: auto;
}

.loader-main {
  border-bottom: 1px dashed rgba(17, 17, 26, 0.5);
}

.loader-main p {
  font-size: 15px;
  font-weight: 700;
}

.flight-img {
  width: 250px;
}

.info-img {
  width: 45px;
}

/* .detail-png img{
      margin-left: 50px;
  } */
.detail-png .para1 {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 5px;
}

.text-center.para2 {
  font-size: 12px;
}

.border-right {
  border-right: 1px dashed rgba(17, 17, 26, 0.5);
}

.message {
  font-size: 12px;
  font-weight: 400;
}

/* .progress {
  height: 7px !important;
  background: none;
  border: 1px solid #002a5f !important;
  overflow: visible !important;
  z-index: -9999;
  position: relative;
  max-width: 700px !important;
  margin: 0% auto;
}

.progress-bar {
  background-color: #002a5f;
  z-index: 1;
  position: relative;
  overflow: visible !important;
  animation: progressLineTransmission 10s linear both;
}

.slideset1 {
  height: 10em;
  position: relative;
  color: white;
  margin: auto;
  display: flex;
  justify-content: center;
  top: 50px;
}

.slideset1 > * {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  animation: 6s autoplay1 infinite;
}

@keyframes autoplay1 {
  0% {
    visibility: visible;
  }
  33.33% {
    visibility: hidden;
  }
}
.slideset1 > *:nth-child(1) {
  animation-delay: 0s;
}
.slideset1 > *:nth-child(2) {
  animation-delay: 2s;
}
.slideset1 > *:nth-child(3) {
  animation-delay: 4s;
}
.slideset1 > *:nth-child(4) {
  animation-delay: 6s;
}
>>> .v-progress-circular--indeterminate > svg {
  animation: progress-circular-rotate 100s linear infinite;
  transform-origin: center center;
  transition: all 100s ease-in-out;
}
@keyframes progressLineTransmission {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

.progress::before {
  content: "";
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #002a5f;
  border: 2px solid #002a5f;
  box-shadow: rgba(255, 255, 51, 0.5) 0px 25px 50px -1px;
  position: absolute;
  bottom: -3px;
  left: -5px;
  z-index: 9999999;
  overflow: visible;
}

.progress::after {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #002a5f;
  border: 2px solid #002a5f;
  box-shadow: rgba(255, 255, 51, 0.5) 0px 25px 50px -1px;
  position: absolute;
  bottom: -3px;
  right: -5px;
  z-index: 9999999;
  overflow: visible;
}

.air-plane {
  position: relative;
  bottom: 12px;
  font-size: 20px;
  font-weight: bold;
}

>>> .v-img__img,
.v-img__picture,
.v-img__gradient,
.v-img__placeholder,
.v-img__error {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 570px) {
  .content-rev {
    display: flex;
    justify-content: center;
  }
}
@media only screen and (max-width: 480px) {
  .air-plane {
    position: relative;
    bottom: 1px;
    font-size: 14px;
  }
  .air1 {
    display: flex;
    justify-content: flex-end;
  }
  .from {
    font-size: 12px;
  }
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@keyframes spinner-rotate {
  100% {
    transform: rotate(1turn);
  }
}

.spinner {
  position: relative;
  z-index: 0;
  height: 250px;
  width: 168px;
  border-radius: 81px;
  overflow: hidden;
  padding: 10px;

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #162849;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(#162849, #162849),
      linear-gradient(#162849, #162849), linear-gradient(#162849, #162849),
      linear-gradient(#fff, #fff);
    animation: spinner-rotate 1s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 5px;
    top: 5px;
    width: calc(100% - 9px);
    height: calc(100% - 9px);
    background: #162849;
    border-radius: 75px;
  }
}

.loader {
  position: relative;
  width: 100px;
  height: 100px;
  top: 36%;
  margin:auto;
}
.loader span {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(calc(18deg * var(--i)));
}
.loader span::before {
  content: "";
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(5px);
  transform: scale(0);
  animation: load 2s linear infinite;
  animation-delay: calc(0.1s * var(--i));
}
@keyframes load {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  10% {
    transform: scale(1.2);
    opacity: 1;
  }
  80%,
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
.plane {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  animation: rotate 2s linear infinite;
  animation-delay: -1.5s;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.plane .icon {
  font-size: 50px;
  color: #fff;
  position: absolute;
  top: -10px;
  left: 80px;
  transform: rotate(45deg);
}
.logo {
  height: 55px;
  cursor: pointer;
  width: 300px;
  filter: grayscale(100%) invert(100%) brightness(2);
}
.datas {
  position: relative;
  z-index: 1;
  width: 400px;
  margin: 0 auto;
  top: 80px;
  margin:auto;
}
.fromdatas,
.todatas {
  font-size: 15px;
}
.departureDate,
.arrivalDate {
  font-size: 14px;
}
.progress-line {
  width: 300px;
  position: relative;
  z-index: 1;
  top: 52% !important;
}
@media screen and (max-width: 480px) {
  .datas {
    position: relative;
    z-index: 1;
    width: 200px !important;
    top: 80px;
  }
  .departureDate,
  .arrivalDate {
    text-align: left !important;
    font-size: 10px !important;
  }
  .fromdatas,
  .todatas {
    font-size: 12px !important;
  }
  .progress-line {
    width: 300px;
    position: relative;
    top: 32% !important;
    z-index: 1;
  }
  .logo {
    height: 50px;
    cursor: pointer;
    width: 250px;
    z-index: 1;
    filter: grayscale(100%) invert(100%) brightness(2);
  }
  .loader {
    position: relative;
    width: 100px;
    height: 100px;
    top: 25%;
    margin:auto;
  }
  .arrowright {
    padding-right: 20px;
    width: 60px !important;
    height: 20px !important;
    color: white;
    margin-bottom: 15px;
  }
  .arrowleft {
    padding-right: 20px;
    width: 60px !important;
    height: 20px !important;
    position: relative;
    top: -20px;
  }
  .arrowright1 {
    padding-right: 20px;
    width: 60px !important;
    height: 20px !important;
    position: relative;
    top: -30px;
  }
  .returndate {
    left: 100px !important;
  }
} */


.logo {
  width:270px;
  height: 55px;
  margin:auto;
  /* filter: brightness(0) invert(1); */
  cursor: pointer;
}
  
  .arrowright {
  /* padding-right: 20px; */
  /* width: 100px; */
  height: 16px;
  filter: invert(1);
  /* color: white; */
  /* margin-bottom: 15px; */
}
.arrowleft {
  /* padding-right: 20px; */
  /* width: 100px; */
  height: 16px;
  filter: invert(1);
  /* position: relative; */
  /* top: -20px; */
}
.arrowright1 {
  padding-right: 20px;
  width: 100px;
  height: 30px;
  position: relative;
  top: -30px;
}

:deep(.v-toolbar .v-toolbar__content){
  height: auto !important;
  display:block;
}

/* text slider style */

.slideset1 {
  /* height: 10em; */
  position: relative;
  color: black;
  /* margin: auto; */
  display: flex;
  /* justify-content: center; */
  /* top: 24px; */
  text-align: center;
}
/* .slideset1 {
  height: 10em;
  position: relative;
  color: white;
  margin: auto;
  display: flex;
  justify-content: center;
  top: 50px;
  } */

  
  .slideset1>* {
    visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  animation: 8s autoplay1 infinite
}

@keyframes autoplay1 {
  0% {
    visibility: visible
  }
  
  25% {
    visibility: hidden
  }
}

.slideset1>*:nth-child(1) {
  animation-delay: 0s
}

.slideset1>*:nth-child(2) {
  animation-delay: 2s
}

.slideset1>*:nth-child(3) {
  animation-delay: 4s
}

.slideset1>*:nth-child(4) {
  animation-delay: 6s
}

@media screen and (max-width: 480px) {
  .loadertextCard p{
    font-size: 12px !important;
  }
}
</style>
