<template>

<v-overlay v-model="overlay" class="d-flex align-center justify-center" @click="datas()">
      <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div v-if="showText || waitText">
        <p class="waitText m-0">Please wait while we confirm the seats and complete the booking...</p>
      </div>
    </v-overlay>
</template>

<script>
export default{
  props: {
    showText: {
      type: Boolean,
      default: false,
    },
  },
    data(){
        return{
            overlay: true,
        }
    },
    watch: {
      "$route.path": {
      immediate: true,
      handler(newValue) {
        let bookingIdObj = null;

        bookingIdObj = this.$route.query.booking_id ? JSON.parse(this.$route.query.booking_id) : null;

        let getrouterpnr = bookingIdObj?.pnr || null;
        // let getrouterbookingid = bookingIdObj?.booking_Id || null;
        console.log(this.$route,'sdasdassdasdsa');
        
        if (newValue === "/bookingView" && getrouterpnr) {
          this.waitText = true;
        } else {
          this.waitText = false;
        }
      },
    },
        overlay(newValue) {
      this.overlay=true
            console.log('Overlay state changed to:', newValue);
        },},
    methods:{
        datas(){
            console.log(this.overlay,'overlayoverlay')
        }
    },

}


</script>

<style>
.lds-roller {
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
  margin:auto;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.waitText {
  font-size: 16px;
  font-weight: 500;
  color: white;
}


</style>
